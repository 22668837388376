body {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
  color: #eef9ff;
}

.arrow-blue-dark * {
  fill: #2779bd;
}

.transition-bg {
  transition: background 0.5s;
}

.simple-keyboard.hg-layout-default .hg-button.hg-red {
  background: #e3342f;
}

.simple-keyboard.hg-layout-default .hg-button.hg-blue {
  background: #3490dc;
}
